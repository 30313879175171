import { render, staticRenderFns } from "./Authentication.vue?vue&type=template&id=003f1ff5&scoped=true"
import script from "./js/authentication.js?vue&type=script&lang=js&external"
export * from "./js/authentication.js?vue&type=script&lang=js&external"
import style0 from "./Authentication.vue?vue&type=style&index=0&id=003f1ff5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003f1ff5",
  null
  
)

export default component.exports