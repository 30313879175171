import { mapActions } from 'vuex'
export default {
  data: () => ({
    message: 'Authenticating...'
  }),
  created() {
    this.authenticate()
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  methods: {
    ...mapActions('auth', ['loginPrakerja']),
    authenticate() {
      const route = this.$route
      const token = route.query.t
      if (!token) {
        this.message = 'Authentication is Failed! Redirecting...'
        const self = this
        setTimeout(function () {
          self.$router.push('/')
        }, 1000)
      } else {
        this.loginPrakerja({
          payloads: {
            adminToken: token,
            formCode: 'studentLogin'
          }
        })
          .then(this.loginSuccess)
          .catch(this.loginFail)
      }
    },
    loginSuccess(response) {
      const res = response.data.data
      localStorage.setItem('user_id', res.id)
      localStorage.setItem('user_name', res.name)
      localStorage.setItem('access_token', res.token)
      localStorage.setItem('user_roles', res.roles)
      this.$router.push('/user/profile')
    },
    loginFail() {
      this.message = 'Authentication is Failed! Redirecting...'
      const self = this
      setTimeout(function () {
        self.$router.push('/')
      }, 1000)
    }
  }
}
